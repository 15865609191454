/*
    - Global CSS Module File
*/

.successText {
    color: #388E3C;
}

.dangerText {
    color: #ee2e24;
}

.actionButton {
    margin: 0 0.1rem;
}

.floatRight {
    float: right;
}

.hoverEffect {
    cursor: pointer;
    transition: all 0.3s;
}